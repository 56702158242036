import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const EditProjects = ({ openModal, handleModal, getProjects, news }) => {
  console.log("newsnews", news);
  const [editprojects, setEditprojects] = useState({
    id: news?._id,
    title: news?.title,
  });
  const [photo, setPhoto] = useState(news?.photo?.split("-")[1]);

  const handleInput = (e) => {
    setEditprojects({ ...editprojects, [e.target.name]: e.target.value });
  };

  const handleAddFile = (e) => {
    setPhoto(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editprojects.title === "") {
      return toast.error("Please enter title");
    }

    const formData = new FormData();
    formData.append("title", editprojects.title);
    formData.append("photo", photo);
    api("put", `/projects/${editprojects?.id}`, formData)
      .then((res) => {
        console.log(res, "12345432");
        toast.success("Project edit successfully");
        getProjects();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit a Projects
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {editprojects.title ? "" : "*"}{" "}
                          </span>
                          Title
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Title Here"
                          type="text"
                          value={editprojects.title}
                          name="title"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: "red" }}>
                            {photo ? "" : "*"}{" "}
                          </span>{" "}
                          photo
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="file"
                          name="photo"
                          onChange={handleAddFile}
                        />
                        <p>{news?.photo ? news?.photo?.split(" ") : ""}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditProjects;
