import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "api/index";
import {
  Card,
  CardHeader,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";

import AddVideo from "./Addvideos";
import EditVideo from "./Editvideos";
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";

const Videos = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const history = useHistory();
  const [videos, setVideos] = useState([]);
  console.log("videos", videos);
  const [videosById, setVideosById] = useState({
    _id: "",
    title: "",
    video: "",
  });
  console.log("videos 123", videosById);

  const getVideos = async () => {
    console.log("videso63");

    const data = await api("get", "/videos");
    setVideos(data?.videos);
  };

  useEffect(() => {
    console.log("videso63");
    getVideos();
  }, []);
  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleEditModal = (item) => {
    console.log("item", item);
    setVideosById(item);
    setEditModal((open) => !open);
    return openModal;
  };

  const handleDelete = (id) => {
    api("delete", `/videos/${id}`).then(() => {
      toast.success("Video deleted successfully");
      getVideos();
    });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Videos</h3>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleModal}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Video
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Video</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {videos?.map((item) => {
                    console.log("videosss", videos);
                    return (
                      <tr>
                        <td>{item.title}</td>
                        <td>{item.video.split("-")[0]}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {item.test_conducted && (
                                <DropdownItem
                                  onClick={() =>
                                    history.push(`/admin/users/${item._id}`)
                                  }
                                >
                                  View
                                </DropdownItem>
                              )}
                              <DropdownItem
                                href="#pablo"
                                onClick={() => handleEditModal(item)}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                className="text-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {openModal && (
          <AddVideo
            openModal={openModal}
            handleModal={handleModal}
            getVideos={getVideos}
          />
        )}
        {editModal && (
          <EditVideo
            openModal={editModal}
            handleModal={handleEditModal}
            videos={videosById}
            setNews={setVideos}
            getVideos={getVideos}
          />
        )}
      </Container>
    </>
  );
};

export default Videos;
