import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const AddProjects = ({ openModal, handleModal, getProjects }) => {
  let [addprojects, setAddprojects] = useState({
    title: "",
    photo: "",
  });

  const handleInput = (e) => {
    if (e.target.name === "photo") {
      setAddprojects({ ...addprojects, [e.target.name]: e.target.files[0] });
    } else {
      setAddprojects({ ...addprojects, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addprojects.title === "") {
      return toast.error("Please enter title");
    }

    if (addprojects.photo === "") {
      return toast.error("Photo is required");
    }

    const formData = new FormData();
    formData.append("title", addprojects.title);
    formData.append("photo", addprojects.photo);
    console.log("objectttt", formData);
    api("post", "/projects", formData)
      .then((res) => {
        toast.success("Project added successfully");
        getProjects();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Add a Project
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {addprojects.title ? "" : "*"}{" "}
                          </span>
                          Title
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter title here"
                          type="text"
                          value={addprojects.title}
                          name="title"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: "red" }}>
                            {addprojects.photo ? "" : "*"}
                          </span>
                          photo
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="file"
                          name="photo"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddProjects;
