import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const AddVideo = ({ openModal, handleModal, getVideos }) => {
  const [videos, setVideos] = useState({
    title: "",
    video: "",
  });
  console.log("state", videos);

  const handleInput = (e) => {
    if (e.target.name === "title") {
      setVideos({ ...videos, [e.target.name]: e.target.value });
    } else {
      console.log("e.target.files[0]", e.target.files[0]);

      setVideos({ ...videos, [e.target.name]: e.target.files[0] });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (videos.title === "") {
      return toast.error("Please Enter Title");
    }

    if (videos.video === "") {
      return toast.error("Video is Required");
    }

    const formData = new FormData();
    formData.append("title", videos.title);
    formData.append("video", videos.video);
    api("post", "/videos/", formData)
      .then((res) => {
        toast.success("Video added successfully");
        getVideos();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Add a Videos
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {videos.title ? "" : "*"}{" "}
                          </span>
                          Title
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter title here"
                          type="text"
                          value={videos.title}
                          name="title"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}></Col>
                  </Row>

                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: "red" }}>
                            {videos.video ? "" : "*"}
                          </span>
                          Add Video
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="file"
                          name="video"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddVideo;
