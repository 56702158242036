/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const handleSignOut = () => {
  localStorage.removeItem("authToken");

  const history = useHistory();
  history.push("/auth/login");

  toast.success("You have successfully signed out");
};

const SignOutPage = () => {
  return (
    <div className="header">
      <Button
        color="danger"
        onClick={handleSignOut}
        style={{
          marginLeft: "auto",
        }}
      >
        Sign Out
      </Button>
    </div>
  );
};

export default SignOutPage;
