import { useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

const AddNews = ({ openModal, handleModal, getNews }) => {
  let [addnews, setAddnews] = useState({
    title: "",
    photo: "",
    details: "",
  });

  const handleInput = (e) => {
    if (e.target.name === "photo") {
      setAddnews({ ...addnews, [e.target.name]: e.target.files[0] });
    } else {
      setAddnews({ ...addnews, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (addnews.title === "") {
      return toast.error("Please Enter Title");
    }
    if (addnews.photo === "") {
      return toast.error("Photo is Required");
    }
    if (addnews.details === "") {
      return toast.error("Please Enter Details");
    }

    const formData = new FormData();
    formData.append("title", addnews.title);
    formData.append("description", addnews.description);
    formData.append("details", addnews.details);
    formData.append("photo", addnews.photo);
    console.log("formData--------------------->", formData);
    api("post", "/news", formData)
      .then((res) => {
        console.log(res, "12345432");
        toast.success("News added successfully");
        getNews();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Add News
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {addnews.title ? "" : "*"}{" "}
                          </span>
                          Title
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter News Title Here"
                          type="text"
                          value={addnews.title}
                          name="title"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" style={{ margin: "auto" }}></Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: "red" }}>
                            {addnews.photo ? "" : "*"}
                          </span>
                          Photo
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="file"
                          name="photo"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">Details</label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter News Details Here"
                          type="text"
                          value={addnews.details}
                          name="details"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddNews;
