/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
// import Users from "views/users/Users";
import News from "views/News/News";
import Projects from "views/Projects/Projects";
import Videos from "views/Videos/Videos";
import Register from "views/Register.js";
import Login from "views/Login.js";
import SignOutPage from "views/SignOut/SignOutPage";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-red",
    component: Index,
    layout: "/admin",
  },
  // {
  //   path: "/users",
  //   name: "All Users",
  //   icon: "ni ni-single-02 text-primary",
  //   component: Users,
  //   layout: "/admin",
  // },
  {
    path: "/news",
    name: "News",
    icon: "ni ni-single-02 text-primary",
    component: News,
    layout: "/admin",
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "ni ni-single-02 text-primary",
    component: Projects,
    layout: "/admin",
  },
  {
    path: "/videos",
    name: "Videos",
    icon: "ni ni-single-02 text-primary",
    component: Videos,
    layout: "/admin",
  },

  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-circle-08 text-pink",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/signout",
    name: "Sign Out",
    icon: "ni ni-user-run text-danger",
    component: SignOutPage,
    layout: "/auth",
  },
];
export default routes;
