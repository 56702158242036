import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
const EditVideo = ({ openModal, handleModal, getVideos, videos }) => {
  const [updatedVideo, setUpdatedVideo] = useState({
    _id: "",
    title: "",
    video: "",
  });

  useEffect(() => {
    setUpdatedVideo({
      _id: videos?._id,
      title: videos?.title,
      video: videos?.video,
    });
  }, []);

  const [clip, setClip] = useState(videos?.video?.split("-")[1]);

  const handleInput = (e) => {
    if (e.target.name === "title") {
      setUpdatedVideo({ ...updatedVideo, [e.target.name]: e.target.value });
    } else {
      console.log("e.target.files[0]", e.target.files[0]);
      setUpdatedVideo({ ...updatedVideo, [e.target.name]: e.target.files[0] });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (updatedVideo.title === "") {
      return toast.error("Please enter title");
    }

    if (updatedVideo.video === "") {
      return toast.error("enter details");
    }

    const formData = new FormData();
    formData.append("title", updatedVideo.title);
    formData.append("video", updatedVideo.video);

    console.log("updatedVideo.thumbnail", updatedVideo);
    api("put", `/videos/${videos?._id}`, formData)
      .then((res) => {
        toast.success("Video edit successfully");
        getVideos();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit Video
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {updatedVideo.title ? "" : "*"}{" "}
                          </span>
                          Title
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter title here"
                          type="text"
                          value={updatedVideo.title}
                          name="title"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          <span style={{ color: "red" }}>
                            {updatedVideo.video ? "" : "*"}
                          </span>
                          Add Video
                        </label>
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          type="file"
                          name="video"
                          onChange={handleInput}
                        />
                        <p>{clip && clip}</p>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditVideo;
