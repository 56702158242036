import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "api/index";
import {
  Card,
  CardHeader,
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";

// core components
import AddProjects from "./Addprojects";
import EditProjects from "./Editprojects";
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";

const Projects = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const history = useHistory();
  const [news, setNews] = useState([]);
  const [newsById, setNewsById] = useState({
    photo: "",
    title: "",
    _id: "",
  });
  console.log("news", news);
  console.log("newsById", newsById);
  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const data = await api("get", "/projects");
    setNews(data?.news);
    console.log("data1", data);
  };

  const handleModal = () => {
    setOpenModal((open) => !open);
    return openModal;
  };

  const handleEditModal = (item) => {
    console.log("item", item);
    setNewsById(item);
    setEditModal((open) => !open);
    return openModal;
  };

  const handleDelete = (id) => {
    api("delete", `/projects/${id}`).then(() => {
      toast.success("Project deleted successfully");
      getProjects();
    });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Projects</h3>
                  <Button
                    color="primary"
                    href="#pablo"
                    onClick={handleModal}
                    size="md"
                  >
                    <i className="ni ni-fat-add"></i>
                    Add Project
                  </Button>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Photos</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {news?.map((item) => {
                    return (
                      <tr>
                        <td>{item.title}</td>
                        <td>
                          <div className="img_div_pannel">
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                objectFit: "cover",
                              }}
                              src={`http://localhost:5000/${item.photo}`}
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {item.test_conducted && (
                                <DropdownItem
                                  onClick={() =>
                                    history.push(`/admin/users/${item._id}`)
                                  }
                                >
                                  View
                                </DropdownItem>
                              )}
                              <DropdownItem
                                href="#pablo"
                                onClick={() => handleEditModal(item)}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                className="text-danger"
                                onClick={() => handleDelete(item._id)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        {openModal && (
          <AddProjects
            openModal={openModal}
            handleModal={handleModal}
            getProjects={getProjects}
          />
        )}
        {editModal && (
          <EditProjects
            openModal={editModal}
            handleModal={handleEditModal}
            news={newsById}
            setNews={setNews}
            getProjects={getProjects}
          />
        )}
      </Container>
    </>
  );
};

export default Projects;
